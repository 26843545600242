import axios from "axios";
window.__request = axios.CancelToken.source();
const state = {
  user: null,
  loggedOut: false,
  loggingOut: false,
  roles: [],
  addressinfo: [],
  countries: [],
  states: [],
  search: [],
  myquote: [],
  chekout: [],
  myorder: [],
  priceLvl: "none",
};

const getters = {
  user: (state) => state.user,
  isLoggedOut: (state) => state.loggedOut,
  isLoggingOut: (state) => state.loggingOut,
  roles: (state) => state.roles,
  countries: (state) => state.countries,
  states: (state) => state.states,
  addressinfo: (state) => state.addressinfo,
  search: (state) => state.search,
  myquote: (state) => state.myquote,
  chekout: (state) => state.chekout,
  searchproductlist: (state) => state.searchproductlist,
  searchchkboxes: (state) => state.searchchkboxes,
  myorder: (state) => state.myorder,
  priceLvl: (state) => state.priceLvl,
};

const actions = {
  /**
   * Registers the new User.
   *
   * @param {Function} commit
   * @param {Form} form
   * @return {Promise}
   */
  registerRequest({ commit }, { form }) {
    return form
      .post(`/api/auth/register`)
      .then((response) => {
        return response;
      });
  },
  /**
   * Logs the user in to the application.
   *
   * @param {Function} commit
   * @param {Form} form
   * @return {Promise}
   */
  login({ commit }, form) {
    return form.post(`/api/auth/login`).then((response) => {
      commit("USER_AUTHENTICATED", response.data);

      return response;
    });
  },
  forgotPassword({ commit }, form) {
    return form
      .post(`/api/auth/forgotpassword`)
      .then((response) => {
        return response;
      });
  },
  newpassword({ commit }, form) {
    return form
      .post(`/api/auth/newpassword`)
      .then((response) => {
        return response;
      });
  },
  verifyAccount({ commit }, token) {
    return axios
      .get(`/api/verify-account/${token}`)
      .then((response) => {
        return response;
      });
  },

  /**
   * Logs the user out of the application.
   *
   * @param commit
   * @param form
   * @returns {Promise}
   */
  logout({ commit }, form) {
    commit("USER_LOGGING_OUT");

    return form
      .delete(`/api/auth/logout`)
      .then((response) => {
        commit("USER_LOGGED_OUT");

        return response;
      });
  },

  /**
   * Marks the user as logging out on the state.
   *
   * @param commit
   * @returns {void}
   */
  loggingOut({ commit }) {
    commit("USER_LOGGING_OUT");
  },

  /**
   * Marks the user as logged out on the state.
   *
   * @param commit
   * @param state
   * @returns {void}
   */
  loggedOut({ commit, state }) {
    if (state.loggingOut) {
      return;
    }

    commit("USER_LOGGED_OUT");
  },

  /**
   * Loads the authenticated user from the backend.
   *
   * @param {Function} commit
   * @param {Object} data
   * @return {Promise}
   */
  setUserData({ commit }, data) {
    if (data && data.hasOwnProperty("user")) {
      commit("USER_AUTHENTICATED", data);
    } else {
      commit("USER_UNAUTHORIZED", data);
    }
  },
  setPriceLvl({ commit }, data) {
    commit("PRICE_LVL", data);
  },

  resetPassword({ commit }, form) {
    return form
      .post(`/api/users/reset-password/${state.user.id}`)
      .then((response) => {
        return response;
      });
  },

  newPassword({ commit }, { token, form }) {
    return form
      .post(`/api/auth/new-password/${token}`)
      .then((response) => {
        return response;
      });
  },

  genNewPassword({ commit }, { token, form }) {
    return form
      .post(`/api/auth/gen-new-password/${token}`)
      .then((response) => {
        return response;
      });
  },

  loadRoles({ commit }) {
    return axios.get(`/api/roles`).then((response) => {
      commit("USER_ROLES_LOADED", response.data);

      return response;
    });
  },
  addAddress({ commit }, { form, user_id }) {
    return form
      .post(`/api/addaddress/${user_id}`)
      .then((response) => {
        commit("ADDRESS_LOADED", response.data);

        return response;
      });
  },
  getAddress({ commit }, address_id) {
    return axios
      .get(`/api/getaddress/${address_id}`)
      .then((response) => {
        commit("ADDRESS_LOADED", response.data);

        return response;
      });
  },
  editAddress({ commit }, { form, address_id }) {
    return form
      .post(`/api/editaddress/${address_id}`)
      .then((response) => {
        commit("ADDRESS_LOADED", response.data);

        return response;
      });
  },
  makeDefaultAddress(
    { commit },
    { user_id, is_check, address_id }
  ) {
    return axios
      .get(
        `/api/makedefaultaddress/${user_id}/${is_check}/${address_id}`
      )
      .then((response) => {
        commit("ADDRESS_LOADED", response.data);

        return response;
      });
  },
  deleteAddress({ commit }, address_id) {
    return axios
      .get(
        `/api/deleteaddress/${state.user.id}/${address_id}`
      )
      .then((response) => {
        commit("ADDRESS_LOADED", response.data);

        return response;
      });
  },
  loadAddressInfo({ commit }) {
    return axios
      .get(`/api/addressinfo/${state.user.id}`)
      .then((response) => {
        commit("ADDRESS_LOADED", response.data);

        return response;
      });
  },
  editAccountInfo({ commit }, { user_id, form }) {
    return form
      .post(`/api/editaccountinfo/${user_id}`)
      .then((response) => {
        return response;
      });
  },
  loadCountries({ commit }) {
    return axios.get(`/api/countries`).then((response) => {
      commit("COUNTRY_LOADED", response.data);

      return response;
    });
  },
  loadCountryDetails({ commit }, id) {
    return axios
      .get(`/api/countrydetails/${id}`)
      .then((response) => {
        return response.data;
      });
  },
  getState({ commit }, id) {
    return axios
      .post(`/api/states/${id}`)
      .then((response) => {
        commit("STATES_LOADED", response.data);
        return response;
      });
  },
  getSearchresult({ commit }, requestquery) {
    window.__request.cancel();
    window.__request = axios.CancelToken.source();
    return axios
      .get(`/api/search?${requestquery}`, {
        cancelToken: window.__request.token,
      })
      .then((response) => {
        commit("PRODUCTS_LOADED", response.data);
        return response;
      });
  },
  loadMyQuotes({ commit }) {
    return axios
      .get(`/api/myquotes/${state.user.id}`)
      .then((response) => {
        commit("QUOTE_LOADED", response.data);

        return response;
      });
  },
  myQuoteDelete({ commit }, { product_id }) {
    return axios
      .get(`/api/myquotedelete/${product_id}`)
      .then((response) => {
        return response;
      });
  },
  viewQuote({ commit }, { quote_id }) {
    return axios
      .get(`/api/viewquote/${quote_id}`)
      .then((response) => {
        commit("QUOTE_LOADED", response.data);
        return response;
      });
  },
  viewCheckoutQuote({ commit }, { token }) {
    return axios
      .get(`/api/viewcheckoutquote/${token}`)
      .then((response) => {
        commit("QUOTE_LOADED", response.data);
        return response;
      });
  },
  viewCheckoutCart({ commit }, { token }) {
    return axios
      .post(`/api/viewcheckoutcart/${token}`)
      .then((response) => {
        commit("CHECKOUT_LOADED", response.data);
        return response;
      });
  },
  loadMyQuotesAll({ commit }) {
    return axios
      .get(`/api/myquotes/all/${state.user.id}`)
      .then((response) => {
        commit("QUOTE_LOADED", response.data);

        return response;
      });
  },
  loadCheckoutAddressInfo({ commit }) {
    return axios
      .get(`/api/checkoutaddressinfo/${state.user.id}`)
      .then((response) => {
        commit("ADDRESS_LOADED", response.data);
        return response;
      });
  },
  checkout({ commit }, { form }) {
    return form.post(`/api/checkout`).then((response) => {
      commit("CHECKOUT_LOADED", response.data);
      return response;
    });
  },
  quoteCheckout({ commit }, { form }) {
    return form
      .post(`/api/quote-checkout`)
      .then((response) => {
        commit("CHECKOUT_LOADED", response.data);
        return response;
      });
  },

  checkoutCart({ commit }, { form }) {
    return form
      .post(`/api/checkoutCart`)
      .then((response) => {
        commit("CHECKOUT_LOADED", response.data);
        return response;
      });
  },
  checkoutStep1({ commit }, { form }) {
    return form
      .post(`/api/checkoutstep1`)
      .then((response) => {
        commit("CHECKOUT_LOADED", response.data);
        return response;
      });
  },
  checkoutStep2({ commit }, { form }) {
    return form
      .post(`/api/checkoutstep2`)
      .then((response) => {
        commit("CHECKOUT_LOADED", response.data);
        return response;
      });
  },
  getFedexRate({ commit }, requestquery) {
    return axios
      .get(`/api/getfedexrate/?${requestquery}`)
      .then((response) => {
        return response;
      });
  },
  getUPSRate({ commit }, requestquery) {
    return axios
      .get(`/ups-rate/upsRateForm.php/?${requestquery}`)
      .then((response) => {
        return response;
      });
  },
  loadMyOrders({ commit }, requestquery) {
    return axios
      .get(
        `/api/myorders/${state.user.id}/?${requestquery}`
      )
      .then((response) => {
        commit("ORDER_LOADED", response.data);

        return response;
      });
  },

  loadTBEOrders({ commit }, requestquery) {
    return axios
      .get(
        `/api/myTBEorders/${state.user.id}/?${requestquery}`
      )
      .then((response) => {
        commit("ORDER_LOADED", response.data);

        return response;
      });
  },
  viewOrder({ commit }, { order_id }) {
    return axios
      .get(`/api/vieworder/${order_id}`)
      .then((response) => {
        commit("ORDER_LOADED", response.data);
        return response;
      });
  },

  viewTBEorder({ commit }, { order_id, user_id }) {
    return axios
      .get(`/api/viewTBEorder/${order_id}/${user_id}`)
      .then((response) => {
        commit("ORDER_LOADED", response.data);
        return response;
      });
  },

  loadInvoicePDF({ commit }, { order_id }) {
    return axios
      .get(`/api/invoicepdf/${order_id}`, {
        responseType: "blob",
      })
      .then((response) => {
        return response;
      });
  },
  loadPackingPDF({ commit }, { FormName, DocRef }) {
    return axios
      .get(`/api/packinglistpdf/${FormName}/${DocRef}`, {
        responseType: "blob",
      })
      .then((response) => {
        return response;
      });
  },
};

const mutations = {
  /**
   * Sets the guest user data.
   *
   * @param {Object} state
   * @return {void}
   */
  USER_UNAUTHORIZED: (state, data) => {
    state.user = data;
  },
  PRICE_LVL: (state, data) => {
    state.priceLvl = data;
    // console.log("state", state);
  },

  /**
   * Sets the current attribute on the state.
   *
   * @param {Object} state
   * @param {Object} data
   * @return {void}
   */
  USER_AUTHENTICATED: (state, data) => {
    if (data.hasOwnProperty("user")) {
      state.user = data.user;
    } else {
      state.user = data.data;
    }

    if (state.social) {
      state.social = null;
    }
  },

  /**
   * Sets the Logging Out phase on the state.
   *
   * @param {Object} state
   * @return {void}
   */
  USER_LOGGING_OUT: (state) => {
    state.loggingOut = true;
  },

  /**
   * Sets the Logged Out phase on the state.
   *
   * @param {Object} state
   * @return {void}
   */
  USER_LOGGED_OUT: (state) => {
    state.loggedOut = true;
    state.user = { id: 0 };
  },

  USER_ROLES_LOADED: (state, data) => {
    if (state.roles === null) {
      state.roles = data;
    } else {
      state.roles = state.roles.concat(data);
    }
  },

  USER_REGISTERED: (state, data) => {
    state.user = data;
  },

  ADDRESS_LOADED: (state, data) => {
    state.addressinfo = data;
  },

  COUNTRY_LOADED: (state, data) => {
    state.countries = data;
  },
  STATES_LOADED: (state, data) => {
    state.states = data;
  },
  SEARCH_LOADED: (state, data) => {
    state.search = data;
  },

  QUOTE_LOADED: (state, data) => {
    state.myquote = data;
  },
  CHECKOUT_LOADED: (state, data) => {
    state.checkout = data;
  },
  ORDER_LOADED: (state, data) => {
    state.myorder = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
