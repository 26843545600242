<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="home-content-wrap-copy" @click="hideFilter">
          <div class="div-block-30" id="main-table">
            <div class="filter-sticky">
              <transition name="slide">
                <div
                  v-if="!isHidden"
                  @click.stop=""
                  class="div-block-29 mobilefilter slidein max-height-135"
                >
                  <div class="filter-reset">
                    <div class="overline">Filter</div>
                    <div>
                      <a
                        class="clearfilter d-inline reset-btn"
                        href="javascript:;"
                        @click="resetFilter"
                      >
                        <b-img
                          class="filterimg"
                          src="/images/reset.svg"
                          alt="reset"
                      /></a>
                    </div>
                    <div
                      style="cursor: pointer; display: inline"
                      @click.stop="toggleFilter"
                    >
                      <b-img
                        class="filterimg"
                        src="/images/close_dark.svg"
                        alt="close"
                      />
                    </div>
                  </div>
                  <div class="filter-types-container">
                    <!-- Type -->
                    <div
                      class="accordbox"
                      v-if="productlist.types.length > 0"
                      v-b-toggle.collapse-type
                    >
                      <div class="divider-copy"></div>
                      <div class="div-block-32">
                        <div>
                          <div>Type</div>
                        </div>
                        <div class="div-block-33">
                          <img
                            src="/images/navigate_next-24px.svg"
                            width="21"
                            height="21"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      id="collapse-type"
                      accordion="my-accordion"
                      visible
                      v-if="productlist.types.length > 0"
                    >
                      <ul>
                        <li v-for="(lt, ltk) in productlist.types" :key="ltk">
                          <md-radio
                            :value="lt"
                            @change="loadFilter()"
                            v-model="form.type"
                            >{{ lt }}</md-radio
                          >
                        </li>
                      </ul>
                      <div class="_35"></div>
                    </b-collapse>
                    <!-- Proprietary -->
                    <div
                      class="accordbox"
                      v-if="productlist.proprietaries.length > 0"
                      v-b-toggle.collapse-proprietary
                    >
                      <div class="divider-copy"></div>
                      <div class="div-block-32">
                        <div>
                          <div>Proprietary</div>
                        </div>
                        <div class="div-block-33">
                          <img
                            src="/images/navigate_next-24px.svg"
                            width="21"
                            height="21"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      id="collapse-proprietary"
                      accordion="my-accordion"
                      v-if="productlist.proprietaries.length > 0"
                    >
                      <ul>
                        <li
                          v-for="(lp, lpk) in productlist.proprietaries"
                          :key="lpk"
                        >
                          <md-radio
                            :value="lp"
                            @change="loadFilter()"
                            v-model="form.proprietary"
                            >{{ lp }}</md-radio
                          >
                        </li>
                      </ul>
                      <div class="_35"></div>
                    </b-collapse>
                    <!-- Head Style -->
                    <div
                      class="accordbox"
                      v-if="productlist.headstyles.length > 0"
                      v-b-toggle.collapse-headstyle
                    >
                      <div class="divider-copy"></div>
                      <div class="div-block-32">
                        <div>
                          <div>Head Style</div>
                        </div>
                        <div class="div-block-33">
                          <img
                            src="/images/navigate_next-24px.svg"
                            width="21"
                            height="21"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      id="collapse-headstyle"
                      accordion="my-accordion"
                      v-if="productlist.headstyles.length > 0"
                    >
                      <ul>
                        <li
                          v-for="(lhs, lhsk) in productlist.headstyles"
                          :key="lhsk"
                        >
                          <md-radio
                            :value="lhs"
                            @change="loadFilter()"
                            v-model="form.head_style"
                            >{{ lhs }}</md-radio
                          >
                        </li>
                      </ul>
                      <div class="_35"></div>
                    </b-collapse>
                    <!-- Material -->
                    <div
                      class="accordbox"
                      v-if="productlist.materials.length > 0"
                      v-b-toggle.collapse-material
                    >
                      <div class="divider-copy"></div>
                      <div class="div-block-32">
                        <div>
                          <div>Material</div>
                        </div>
                        <div class="div-block-33">
                          <img
                            src="/images/navigate_next-24px.svg"
                            width="21"
                            height="21"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      id="collapse-material"
                      accordion="my-accordion"
                      v-if="productlist.materials.length > 0"
                    >
                      <ul>
                        <li
                          v-for="(lm, lmk) in productlist.materials"
                          :key="lmk"
                        >
                          <md-radio
                            :value="lm"
                            @change="loadFilter()"
                            v-model="form.material"
                            >{{ lm }}</md-radio
                          >
                        </li>
                      </ul>
                      <div class="_35"></div>
                    </b-collapse>
                    <!-- Finish -->
                    <div
                      class="accordbox"
                      v-if="productlist.finishes.length > 0"
                      v-b-toggle.collapse-finish
                    >
                      <div class="divider-copy"></div>
                      <div class="div-block-32">
                        <div>
                          <div>Finish</div>
                        </div>
                        <div class="div-block-33">
                          <img
                            src="/images/navigate_next-24px.svg"
                            width="21"
                            height="21"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      id="collapse-finish"
                      accordion="my-accordion"
                      v-if="productlist.finishes.length > 0"
                    >
                      <ul>
                        <li
                          v-for="(lf, lfk) in productlist.finishes"
                          :key="lfk"
                        >
                          <md-radio
                            :value="lf"
                            @change="loadFilter()"
                            v-model="form.finish"
                            >{{ lf }}</md-radio
                          >
                        </li>
                      </ul>
                      <div class="_35"></div>
                    </b-collapse>
                    <!-- Diameter -->
                    <div
                      class="accordbox"
                      v-if="productlist.diameters.length > 0"
                      v-b-toggle.collapse-diameter
                    >
                      <div class="divider-copy"></div>
                      <div class="div-block-32">
                        <div>
                          <div>Diameter</div>
                        </div>
                        <div class="div-block-33">
                          <img
                            src="/images/navigate_next-24px.svg"
                            width="21"
                            height="21"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      id="collapse-diameter"
                      accordion="my-accordion"
                      v-if="productlist.diameters.length > 0"
                    >
                      <ul>
                        <li
                          v-for="(ld, ldk) in productlist.diameters"
                          :key="ldk"
                        >
                          <md-radio
                            :value="ld"
                            @change="loadFilter()"
                            v-model="form.dia"
                            >{{ ld }}</md-radio
                          >
                        </li>
                      </ul>
                      <div class="_35"></div>
                    </b-collapse>
                    <!-- Length -->
                    <div
                      class="accordbox"
                      v-if="productlist.len.length > 0"
                      v-b-toggle.collapse-lengths
                    >
                      <div class="divider-copy"></div>
                      <div class="div-block-32">
                        <div>
                          <div>Length</div>
                        </div>
                        <div class="div-block-33">
                          <img
                            src="/images/navigate_next-24px.svg"
                            width="21"
                            height="21"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      id="collapse-lengths"
                      accordion="my-accordion"
                      v-if="productlist.len.length > 0"
                    >
                      <ul>
                        <li v-for="(ll, llk) in productlist.len" :key="llk">
                          <md-radio
                            :value="ll"
                            @change="loadFilter()"
                            v-model="form.len"
                            >{{ ll }}</md-radio
                          >
                        </li>
                      </ul>
                      <div class="_35"></div>
                    </b-collapse>
                    <!-- Material spec -->
                    <div
                      class="accordbox"
                      v-if="productlist.material_specs.length > 0"
                      v-b-toggle.collapse-material_spec
                    >
                      <div class="divider-copy"></div>
                      <div class="div-block-32">
                        <div>
                          <div>Material spec</div>
                        </div>
                        <div class="div-block-33">
                          <img
                            src="/images/navigate_next-24px.svg"
                            width="21"
                            height="21"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <b-collapse
                      id="collapse-material_spec"
                      accordion="my-accordion"
                      v-if="productlist.material_specs.length > 0"
                    >
                      <ul>
                        <li
                          v-for="(lms, lmsk) in productlist.material_specs"
                          :key="lmsk"
                        >
                          <md-radio
                            :value="lms"
                            @change="loadFilter()"
                            v-model="form.material_spec"
                            >{{ lms }}</md-radio
                          >
                        </li>
                      </ul>
                      <div class="_35"></div>
                    </b-collapse>
                  </div>
                </div>
              </transition>
            </div>
            <div
              class="text-block-4 h6"
              v-if="
                this.categoryName == 'aluminum' ||
                this.categoryName == 'brass' ||
                this.categoryName == 'copper' ||
                this.categoryName == 'industrial' ||
                this.categoryName == 'military' ||
                this.categoryName == 'pop' ||
                this.categoryName == 'stainless-steel' ||
                this.categoryName == 'steel'
              "
            >
              <span
                @click.stop="toggleFilter"
                class="d-inline text-light filter-box"
              >
                <b-img class="filterimg" src="/images/filter.svg" alt="plus" />
                Filter
              </span>
              <h1 class="category_heading">
                Featured Rivets > {{ categoryName }}
              </h1>
            </div>
            <div class="text-block-4 h6 d-flex heading-correction" v-else>
              <span
                @click.stop="toggleFilter"
                class="d-inline text-light filter-box"
              >
                <b-img class="filterimg" src="/images/filter.svg" alt="plus" />
                Filter
              </span>
              <h1 class="category_heading">{{ categoryName }}</h1>
            </div>
            <div>
              <!-- Main table element -->
              <b-table
                v-if="
                  this.categoryName == 'aircraft-blind' ||
                  this.categoryName == 'commercial-blind'
                "
                show-empty
                responsive
                :class="`productlisttable ${!isHidden ? 'has-overlay' : ''}`"
                :items="loadProducts"
                :fields="fields"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(name)="row">
                  <router-link
                    :to="getSinglePageLink(row)"
                    class="cell-link productname"
                  >
                    {{ row.item.name }}
                  </router-link>
                </template>
                <template v-slot:cell()="row">
                  <div class="cell-text">{{ row.value }}</div>
                </template>
                <template v-slot:cell(onhand)="row">
                  <div class="cell-text">
                    <strong>{{ row.item.onhand }}</strong>
                  </div>
                </template>

                <template v-slot:cell(addtoquote)="row">
                  <router-link
                    :to="getSinglePageLink(row)"
                    class="cell-link productname"
                    style="
                      background: transparent;
                      color: #f2612c;
                      font-weight: bold;
                    "
                  >
                    Proceed
                  </router-link>
                  <!-- <input
                    :id="'cart_avl_qtybox_' + row.item.id"
                    v-model="row.item.onhand"
                    type="hidden"
                  />
                  <input
                    :id="'cart_uom_' + row.item.id"
                    :value="row.item.stock_uom"
                    type="hidden"
                  />
                  <md-checkbox
                    :value="row.item.id"
                    v-model="chkboxes.chk"
                    :class="'checkbox' + row.item.id"
                    :id="'checkbox_' + row.item.id"
                    @change="submitQuote(row.item.id)"
                  ></md-checkbox> -->
                </template>
              </b-table>
              <b-table
                v-else-if="
                  this.categoryName == 'rivet-nuts' ||
                  this.categoryName == 'threaded-insert'
                "
                show-empty
                responsive
                :class="`productlisttable ${!isHidden ? 'has-overlay' : ''}`"
                :items="loadProducts"
                :fields="fields2"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(name)="row">
                  <router-link
                    :to="getSinglePageLink(row)"
                    class="cell-link productname"
                  >
                    {{ row.item.name }}
                  </router-link>
                </template>
                <template v-slot:cell()="row">
                  <div class="cell-text">{{ row.value }}</div>
                </template>
                <template v-slot:cell(onhand)="row">
                  <div class="cell-text">
                    <strong>{{ row.item.onhand }}</strong>
                  </div>
                </template>
                <!-- <template v-slot:cell(quantity)="row">
                  <div class="samediv quantity">
                    <input
                      placeholder="001"
                      type="number"
                      step="any"
                      v-model="chkboxes.qty[row.item.id]"
                      :id="'listqtybox_' + row.item.id"
                      :readonly="chkboxes.chk.includes(row.item.id)"
                      min="1"
                    />
                  </div>
                </template> -->
                <template v-slot:cell(addtoquote)="row">
                  <router-link
                    :to="getSinglePageLink(row)"
                    class="cell-link productname"
                    style="
                      background: transparent;
                      color: #f2612c;
                      font-weight: bold;
                    "
                  >
                    Proceed
                  </router-link>
                  <!-- <md-checkbox
                    :value="row.item.id"
                    v-model="chkboxes.chk"
                    :class="'checkbox' + row.item.id"
                    :id="'checkbox_' + row.item.id"
                    @change="submitQuote(row.item.id)"
                  ></md-checkbox>
                  <input
                    :id="'cart_avl_qtybox_' + row.item.id"
                    v-model="row.item.onhand"
                    type="hidden"
                  />
                  <input
                    :id="'cart_uom_' + row.item.id"
                    :value="row.item.stock_uom"
                    type="hidden"
                  /> -->
                </template>
              </b-table>
              <b-table
                v-else-if="this.categoryName == 'tools'"
                show-empty
                responsive
                :class="`productlisttable ${!isHidden ? 'has-overlay' : ''}`"
                :items="loadProducts"
                :fields="fields3"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(name)="row">
                  <router-link
                    :to="getSinglePageLink(row)"
                    class="cell-link productname"
                  >
                    {{ row.item.name }}
                  </router-link>
                </template>
                <template v-slot:cell()="row">
                  <div class="cell-text">{{ row.value }}</div>
                </template>
                <!-- <template v-slot:cell(quantity)="row">
                  <div class="samediv quantity">
                    <input
                      placeholder="001"
                      type="number"
                      step="any"
                      v-model="chkboxes.qty[row.item.id]"
                      :id="'listqtybox_' + row.item.id"
                      :readonly="chkboxes.chk.includes(row.item.id)"
                      min="1"
                    />
                  </div>
                </template> -->
                <template v-slot:cell(addtoquote)="row">
                  <router-link
                    :to="getSinglePageLink(row)"
                    class="cell-link productname"
                    style="
                      background: transparent;
                      color: #f2612c;
                      font-weight: bold;
                    "
                  >
                    Proceed
                  </router-link>
                  <!-- <md-checkbox
                    :value="row.item.id"
                    v-model="chkboxes.chk"
                    :class="'checkbox' + row.item.id"
                    :id="'checkbox_' + row.item.id"
                    @change="submitQuote(row.item.id)"
                  ></md-checkbox>
                  <input
                    :id="'cart_avl_qtybox_' + row.item.id"
                    v-model="row.item.onhand"
                    type="hidden"
                  />
                  <input
                    :id="'cart_uom_' + row.item.id"
                    :value="row.item.stock_uom"
                    type="hidden"
                  /> -->
                </template>
              </b-table>
              <b-table
                v-else
                show-empty
                responsive
                :class="`productlisttable ${!isHidden ? 'has-overlay' : ''}`"
                :items="loadProducts"
                :fields="fields1"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:cell(name)="row">
                  <router-link
                    :to="getSinglePageLink(row)"
                    class="cell-link productname"
                  >
                    {{ row.item.name }}
                  </router-link>
                </template>
                <template v-slot:cell()="row">
                  <div class="cell-text">{{ row.value }}</div>
                </template>
                <template v-slot:cell(onhand)="row">
                  <div class="cell-text">
                    <strong>{{ row.item.onhand }}</strong>
                  </div>
                </template>
                <!-- <template v-slot:cell(quantity)="row">
                  <div class="samediv quantity">
                    <input
                      placeholder="001"
                      type="number"
                      step="any"
                      v-model="chkboxes.qty[row.item.id]"
                      :id="'listqtybox_' + row.item.id"
                      :readonly="chkboxes.chk.includes(row.item.id)"
                      min="1"
                    />
                  </div>
                </template> -->
                <template v-slot:cell(addtoquote)="row">
                  <router-link
                    :to="getSinglePageLink(row)"
                    class="cell-link productname"
                    style="
                      background: transparent;
                      color: #f2612c;
                      font-weight: bold;
                    "
                  >
                    Proceed
                  </router-link>
                  <!-- <md-checkbox
                    :value="row.item.id"
                    v-model="chkboxes.chk"
                    :class="'checkbox' + row.item.id"
                    :id="'checkbox_' + row.item.id"
                    @change="submitQuote(row.item.id)"
                    :disabled="row.item.onhand <= 0 ? true : false"
                  ></md-checkbox>
                  <input
                    :id="'cart_avl_qtybox_' + row.item.id"
                    v-model="row.item.onhand"
                    type="hidden"
                  />
                  <input
                    :id="'cart_uom_' + row.item.id"
                    :value="row.item.stock_uom"
                    type="hidden"
                  /> -->
                </template>
              </b-table>
            </div>
            <div v-if="this.loadProducts.length <= 0" class="nodata">
              <p>No data Found!</p>
            </div>
            <div class="itemspage">
              <label>Items Per Page</label>
              <v-select
                :options="itemspage"
                v-model="form.items"
                @input="loadFilter"
                :clearable="false"
                placeholder="10"
              ></v-select>
            </div>
            <div class="propagination" v-if="loadProducts.length > 0">
              <div class="custompage">
                <b-pagination-nav
                  v-model="currentPage"
                  @input="paginationChange"
                  :link-gen="linkGen"
                  :number-of-pages="Math.ceil(rows / perPage)"
                ></b-pagination-nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../mixins/resources";

export default {
  name: "Direction",
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  beforeMount() {},
  mounted() {
    this.PageloadFilter();
    this.categoryName = this.$route.params.category;
  },
  data() {
    return {
      isHidden: true,
      loadFilters: {
        types: [],
        proprietaries: [],
        headstyles: [],
        materials: [],
        finishes: [],
        diameters: [],
        len: [],
        material_specs: [],
      },
      isReadonly: [],
      categoryName: null,
      rows: 100,
      perPage: 100,
      currentPage: 1,
      itemspage: ["10", "20", "25", "50", "100"],
      loadProducts: [],
      checkboxes: [],
      quantities: [],

      form: {
        type: null,
        proprietary: null,
        head_style: null,
        material: null,
        finish: null,
        dia: null,
        len: null,
        quantity: 1,
        material_spec: null,
        items: "50",
        page: 1,
      },
      fields: [
        {
          key: "name",
          label: "Name",
          thClass: "tbheader",
          tdClass: "tbColumn product-name-column",
        },
        {
          key: "type",
          label: "Type",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "head_style",
          label: "Head Style",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "material",
          label: "Material",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "finish",
          label: "Finish",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "dia",
          label: "Diameter",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "grip",
          label: "Grip Range",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "onhand",
          label: "Qty Available",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        // {
        //   key: "quantity",
        //   label: "Quantity",
        //   thClass: "tbheader",
        //   tdClass: "tbColumn",
        // },
        {
          key: "addtoquote",
          label: "Action",
          thClass: "tbheaderAction",
          tdClass: "tbColumn",
        },
      ],
      fields1: [
        {
          key: "name",
          label: "Name",
          thClass: "tbheader",
          tdClass: "tbColumn product-name-column",
        },
        {
          key: "type",
          label: "Type",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "head_style",
          label: "Head Style",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "material",
          label: "Material",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "finish",
          label: "Finish",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "dia",
          label: "Diameter",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "length",
          label: "Length",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "onhand",
          label: "Qty Available",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        // {
        //   key: "quantity",
        //   label: "Quantity",
        //   thClass: "tbheader",
        //   tdClass: "tbColumn",
        // },
        {
          key: "addtoquote",
          label: "Action",
          thClass: "tbheaderAction",
          tdClass: "tbColumn",
        },
      ],
      fields2: [
        {
          key: "name",
          label: "Name",
          thClass: "tbheader",
          tdClass: "tbColumn product-name-column",
        },
        {
          key: "type",
          label: "Type",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "head_style",
          label: "Head Style",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "material",
          label: "Material",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "finish",
          label: "Finish",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "thread",
          label: "Thread Size",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "grip",
          label: "Grip Range",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "onhand",
          label: "Qty Available",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        // {
        //   key: "quantity",
        //   label: "Quantity",
        //   thClass: "tbheader",
        //   tdClass: "tbColumn",
        // },
        {
          key: "addtoquote",
          label: "Action",
          thClass: "tbheaderAction",
          tdClass: "tbColumn",
        },
      ],
      fields3: [
        {
          key: "name",
          label: "Name",
          thClass: "tbheader",
          tdClass: "tbColumn product-name-column",
        },
        {
          key: "type",
          label: "Type",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "onhand",
          label: "Qty Available",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        // {
        //   key: "quantity",
        //   label: "Quantity",
        //   thClass: "tbheader",
        //   tdClass: "tbColumn",
        // },
        {
          key: "addtoquote",
          label: "Action",
          thClass: "tbheaderAction",
          tdClass: "tbColumn",
        },
      ],
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters([
      "user",
      "cartlist",
      "productlist",
      "chkboxes",
      "showSearch",
      "filterStatus",
    ]),
  },
  watch: {
    showSearch: function (val) {
      if (val == false && this.isHidden == false) {
        // window.scroll({
        //   top: 0,
        //   behavior: "smooth",
        // });
        //window.scrollBy(0, 105);
      }
    },
  },
  methods: {
    submitQuote(prod_id) {
      var Qty = $("#listqtybox_" + prod_id).val();
      if (Qty == "") {
        Qty = 1;
      }
      if (Qty > 0) {
        Qty = Qty;
      } else {
        $("#listqtybox_" + prod_id).removeAttr("readonly");
        this.removeQuote(prod_id);

        this.$notify({
          text: "Quantity should be greater than zero!",
          type: "error",
        });
        return false;
      }
      $("#listqtybox_" + prod_id).attr("readonly", "readonly");
      var items = this.checkboxes;
      var productIn = items.includes(prod_id);
      if (productIn) {
        var avl_val = $("#cart_avl_qtybox_" + prod_id).val();
        var stock_uom = $("#cart_uom_" + prod_id).val();
        var uom = "EA";
        if (stock_uom.includes("LB")) {
          uom = "LB";
        }

        if (prod_id != "") {
          if (parseFloat(Qty) <= parseFloat(avl_val)) {
            this.$store
              .dispatch("quoteSubmit", {
                user_id: this.user.id,
                product_id: prod_id,
                quantity: Qty,
                uom: uom,
                input_mfr: null,
                input_cert: null,
                QtyAvail: avl_val,
              })
              .then(() => {})
              .catch((error) => {
                if (this.form.hasErrors()) {
                  this.$notify({
                    text: "There is some validation error. Please correct and try again!",
                    type: "error",
                  });
                } else {
                  this.$notify({
                    text: "Something went wrong!",
                    type: "error",
                  });
                }
              });
          } else {
            this.$notify({
              text: "Quantity should be less than Available Quantity!",
              type: "error",
            });
          }
        }
      } else {
        // remove from cart
        $("#listqtybox_" + prod_id).removeAttr("readonly");
        this.removeQuote(prod_id);
      }
    },
    removeQuote(prod_id) {
      this.$store
        .dispatch("quoteDeletebyProductId", {
          product_id: prod_id,
        })
        .then(() => {
          /* $(".checkimg" + prod_id).css("display", "none");
                    $(".checkbox" + prod_id).css("display", "inline-flex");*/
          $("#listqtybox_" + prod_id).removeAttr("readonly");
          this.chkboxes.qty[prod_id] = 1;
        })
        .catch((error) => {
          if (this.form.hasErrors()) {
            this.$notify({
              text: "There is some validation error. Please correct and try again!",
              type: "error",
            });
          } else {
            this.$notify({
              text: "Something went wrong!",
              type: "error",
            });
          }
        });
    },
    rowClass(item, type) {
      return "tbRows";
    },
    paginationChange(page) {
      this.form.page = page;
      this.loadFilter(true);
    },
    linkGen(page){
      return {
        path: `/store/category/${this.$route.params.category}`,
        query: { 
          items: this.perPage,
          page: page
        }
      }
    },
    loadCartList() {
      this.isLoading = true;
      this.$store.dispatch("loadCartList").then((quotelist) => {
        this.isLoading = false;
      });
    },
    loadFilter(withHide = false) {
      let _withHide = withHide;
      let url = [];
      if (this.form.type != null) {
        _withHide = false;
        url["type"] = this.form.type;
      }
      if (this.form.proprietary != null) {
        _withHide = false;

        url["proprietary"] = this.form.proprietary;
      }
      if (this.form.head_style != null) {
        _withHide = false;

        url["head_style"] = this.form.head_style;
      }
      if (this.form.material != null) {
        _withHide = false;

        url["material"] = this.form.material;
      }
      if (this.form.finish != null) {
        _withHide = false;

        url["finish"] = this.form.finish;
      }
      if (this.form.dia != null) {
        _withHide = false;

        url["dia"] = this.form.dia;
      }
      if (this.form.len != null) {
        _withHide = false;

        url["len"] = this.form.len;
      }
      if (this.form.material_spec != null) {
        _withHide = false;

        url["material_spec"] = this.form.material_spec;
      }
      if (this.form.items != null) {
        url["items"] = this.form.items;
      }
      if (this.form.sort != null) {
        url["sort"] = this.form.sort;
      }
      url["page"] = this.form.page;

      this.$router.push({ query: url });

      // Not included in url
      if (this.$route.params.category) {
        url["category"] = this.$route.params.category;
        if (_withHide) url["hide0qty"] = true;
      }

      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");

      this.$store.dispatch("loadProducts", queryString).then((loadProducts) => {
        this.rows = loadProducts.data.products.total;
        this.currentPage = this.form.page;
        this.perPage = this.form.items;
        this.loadProducts = loadProducts.data.products.data;
        this.loadFilters.types = loadProducts.data.types;
        this.loadFilters.proprietaries = loadProducts.data.proprietaries;
        this.loadFilters.headstyles = loadProducts.data.headstyles;
        this.loadFilters.materials = loadProducts.data.materials;
        this.loadFilters.finishes = loadProducts.data.finishes;
        this.loadFilters.diameters = loadProducts.data.diameters;
        this.loadFilters.len = loadProducts.data.len;
        this.loadFilters.material_specs = loadProducts.data.material_specs;

        this.checkboxes = this.chkboxes.chk;
        this.quantities = this.chkboxes.qty;
      });
      this.AciveFilter();
    },
    PageloadFilter() {
      let url = [];
      if (this.$route.query.type) {
        url["type"] = this.$route.query.type;
      }
      if (this.$route.query.proprietary) {
        url["proprietary"] = this.$route.query.proprietary;
      }
      if (this.$route.query.head_style) {
        url["head_style"] = this.$route.query.head_style;
      }
      if (this.$route.query.material) {
        url["material"] = this.$route.query.material;
      }
      if (this.$route.query.finish) {
        url["finish"] = this.$route.query.finish;
      }
      if (this.$route.query.dia) {
        url["dia"] = this.$route.query.dia;
      }
      if (this.$route.query.len) {
        url["len"] = this.$route.query.len;
      }
      if (this.$route.query.material_spec) {
        url["material_spec"] = this.$route.query.material_spec;
      }
      if (this.$route.query.items) {
        if (this.$route.query.items >= 10 && this.$route.query.items <= 100) {
          url["items"] = this.$route.query.items;
        } else {
          url["items"] = 50;
        }
      } else {
        url["items"] = this.form.items;
      }
      if (this.$route.query.page) {
        url["page"] = this.$route.query.page;
        this.form.page = this.$route.query.page;
      } else {
        url["page"] = this.form.page;
      }
      if (!this.$route.query.page) {
        this.$router.push({ query: url });
      }

      // Not included in url
      if (this.$route.params.category) {
        url["category"] = this.$route.params.category;
        url["hide0qty"] = true;
      }

      var queryString = Object.keys(url)
        .map((key) => {
          return encodeURIComponent(key) + "=" + encodeURIComponent(url[key]);
        })
        .join("&");

      this.$store.dispatch("loadProducts", queryString).then((loadProducts) => {
        this.rows = this.productlist.products.total;
        this.currentPage = this.form.page;

        this.perPage = this.form.items;
        this.loadProducts = this.productlist.products.data;
        this.loadFilters.types = this.productlist.types;
        this.loadFilters.proprietaries = this.productlist.proprietaries;
        this.loadFilters.headstyles = this.productlist.headstyles;
        this.loadFilters.materials = this.productlist.materials;
        this.loadFilters.finishes = this.productlist.finishes;
        this.loadFilters.diameters = this.productlist.diameters;
        this.loadFilters.len = this.productlist.len;
        this.loadFilters.material_specs = this.productlist.material_specs;

        this.checkboxes = this.chkboxes.chk;
        this.quantities = this.chkboxes.qty;

        $(".nodata").css("display", "block");
      });
      this.AciveFilter();
    },
    AciveFilter() {
      if (this.$route.query.type) {
        this.form.type = this.$route.query.type;
      }
      if (this.$route.query.proprietary) {
        this.form.proprietary = this.$route.query.proprietary;
      }
      if (this.$route.query.head_style) {
        this.form.head_style = this.$route.query.head_style;
      }
      if (this.$route.query.material) {
        this.form.material = this.$route.query.material;
      }
      if (this.$route.query.finish) {
        this.form.finish = this.$route.query.finish;
      }
      if (this.$route.query.dia) {
        this.form.dia = this.$route.query.dia;
      }
      if (this.$route.query.len) {
        this.form.len = this.$route.query.len;
      }
      if (this.$route.query.material_spec) {
        this.form.material_spec = this.$route.query.material_spec;
      }
      if (this.$route.query.items) {
        if (this.$route.query.items >= 10 && this.$route.query.items <= 100) {
          this.form.items = this.$route.query.items;
        } else {
          this.form.items = 50;
        }
      }
      setTimeout(() => {
        $(".page--productlist .productlisttable").css("display", "block");
      }, 900);
    },
    resetFilter() {
      this.form.type = null;
      this.form.proprietary = null;
      this.form.head_style = null;
      this.form.material = null;
      this.form.finish = null;
      this.form.dia = null;
      this.form.len = null;
      this.form.material_spec = null;
      this.form.items = "50";
      this.form.page = 1;
      this.loadFilter();
    },
    makeUrlFriendly(str) {
      str = str.toLowerCase().split("-");

      str = str.map((str) => str.replace(/[^\w\s]/gi, "")).join("-");

      str = str.replace(/\s+/g, "-");

      return str;
    },
    gotosinglePage(row) {
      this.$router
        .push({
          path: `/store/product/${this.makeUrlFriendly(row.item.name)}/${
            row.item.id
          }`,
        })
        .catch(() => {});
    },
    getSinglePageLink(row) {
      return `/store/product/${this.makeUrlFriendly(row.item.name)}/${
        row.item.id
      }`;
    },
    increment(id) {
      var val = parseInt(document.getElementById("listqtybox_" + id).value);
      val = val + 1;
      document.getElementById("listqtybox_" + id).value = val;
    },
    decrement(id) {
      var val = parseInt(document.getElementById("listqtybox_" + id).value);
      if (val === 1) {
        console.log("Negative quantity not allowed");
      } else {
        val = val - 1;
        document.getElementById("listqtybox_" + id).value = val;
      }
    },
    toggleFilter() {
      if (!this.loadProducts.length) {
        return;
      }
      if (this.isHidden) {
        this.isHidden = false;
        // window.scrollTo({
        //   top: 0,
        //   behavior: "smooth",
        // });
        window.scrollTo({
          top: 93,
          behavior: "smooth",
        });
        document
          .getElementsByClassName("page--productlist")[0]
          .classList.add("overflow-y-hidden");
      } else {
        this.isHidden = true;
        document
          .getElementsByClassName("page--productlist")[0]
          .classList.remove("overflow-y-hidden");
      }
      this.$store.commit("FILTER_STATUS", !this.isHidden);
    },
    hideFilter() {
      if (!this.isHidden) {
        this.isHidden = true;
        document
          .getElementsByClassName("page--productlist")[0]
          .classList.remove("overflow-y-hidden");
      }
    },
  },
};
</script>
<style>
.page--productlist .b-table-empty-row.tbRows {
  display: none;
}
.page--productlist .productlisttable {
  display: none;
}
.page--productlist .collapse,
.page--search .collapse {
  overflow-y: auto;
  overflow-x: hidden;
}
.page--productlist .productname:hover {
  text-decoration: underline;
}
.quotebtn {
  position: absolute;
  background: #f2612c;
}
.quotebtn .addquote {
  color: #ffffff;
}
.productlisttable .table .tbColumn .tickimg {
  display: none;
}
.quotebtn .addquote:hover {
  text-decoration: none;
  background-color: #f2612c;
}
.page--productlist .md-radio:not(.md-disabled),
.page--productlist .md-radio:not(.md-disabled) .md-radio-label {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page--productlist .samediv span {
  float: left;
  font-size: 18px;
  border: 1px solid #d2d2d2;
  margin: 0;
  height: 35px;
  line-height: 35px;
  padding: 0 10px;
  font-weight: 500;
  color: #303030;
  -webkit-border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-radius-topleft: 3px;
  -moz-border-radius-bottomleft: 3px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  cursor: pointer;
}
.page--productlist .samediv span.lastspan {
  -webkit-border-top-right-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  -moz-border-radius-topright: 3px;
  -moz-border-radius-bottomright: 3px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  -webkit-border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -moz-border-radius-topleft: 0;
  -moz-border-radius-bottomleft: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 39px;
  padding: 0 8px;
}
.page--productlist .samediv input {
  float: left;
  max-width: 70px;
  font-size: 14px;
  height: 35px;
  text-align: center;
  border: 1px solid #d2d2d2;
  font-weight: 500;
  color: #303030;
  padding-top: 2px;
}
.nodata {
  display: none;
  text-align: center;
}

a.filtershow {
  text-align: left;
}

.w-80 {
  width: 80px;
}

.w-100 {
  width: 100px;
}

.w-120 {
  width: 120px;
}

.w-150 {
  width: 150px;
}

.w-180 {
  width: 180px;
}

.w-200 {
  width: 200px;
}

.backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}

.filtershow {
  z-index: 2000;
}

.home-content-wrap-copy {
  display: block;
  margin-top: 40px;
}

.d-inline {
  display: inline !important;
}

.text-light {
  color: #fff;
}

.font-weight-bold {
  font-weight: 600;
}

.product-name-column {
  background-color: #253c8a !important;
}

.productlisttable .table {
  table-layout: auto !important;
}

.d-flex {
  display: flex;
}

.has-overlay::after {
  content: "";
  display: block;
  position: fixed;
  padding: 100px 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.filterimg {
  height: 18px;
  width: 18px;
  top: -2px;
  position: relative;
}

.filter-box {
  cursor: pointer;
  box-shadow: 1px 0px 0px #6776a7;
  margin-right: 20px;
  padding-right: 20px;
}

.slidein {
  left: 0;
  transition: all 0.5s ease-in-out;
}

/* before the element is shown, start off the screen to the right */
.slide-enter,
.slide-leave-active {
  left: -100%;
}

.filter-sticky {
  position: sticky;
  z-index: 1;
}

.mobilefilter {
  overflow-y: auto;
}

.max-height-135 {
  max-height: calc(100vh - 135px);
  height: 100vh;
}

.max-height-225 {
  max-height: calc(100vh - 225px);
  height: 100vh;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}
.heading-correction {
  padding-left: 20px;
}
.reset-btn {
  margin-right: 15px;
}
.filter-types-container {
  margin: 0px 15px 15px 15px;
}
</style>
