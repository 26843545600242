<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="home-content-wrap-copy">
          <div class="div-block-30">
            <div class="text-block-4 h6">Quote List</div>

            <div v-if="this.miniCartList.length > 0">
              <!-- Main table element -->
              <b-table
                show-empty
                responsive
                class="productlisttable"
                :items="this.miniCartList"
                :fields="fields"
                :tbody-tr-class="rowClass"
              >
                <template v-slot:head(addtoquote)="row">
                  <label>Action</label>
                  <!-- <md-checkbox
                                        value="1"
                                        @change="checkAll()"
                                        v-model="isCheckAll"
                                        :class="'checkbox'"
                                    ></md-checkbox>-->
                </template>
                <template v-slot:cell(name)="row">
                  <div
                    class="cell-link"
                    @click="gotosinglePage(row.item.product_id)"
                  >
                    {{ row.item.name }}
                  </div>
                </template>
                <template v-slot:cell()="row">
                  <div
                    class="cell-text"
                    @click="gotosinglePage(row.item.product_id)"
                  >
                    {{ row.value }}
                  </div>
                </template>
                <template v-slot:cell(additional_notes)="row">
                  <div class="additional_notes">
                    <!-- <span @click="decrement(row.item.id)">-</span> -->
                    <input
                      :id="'additional_notes_' + row.item.id"
                      v-model="row.item.additional_notes"
                      v-on:input="addNotesToUpdate(row.item.id)"
                      type="text"
                    />
                    <!-- <span @click="increment(row.item.id)" class="lastspan">+</span> -->
                  </div>
                  <!-- <a
                    href="javascript:;"
                    @click="updateAdditionalNote(row.item.id)"
                    class="updateqn"
                  >
                    <b-img src="/images/update.svg"></b-img>Update
                  </a> -->
                </template>
                <template v-slot:cell(quantity)="row">
                  <div class="samediv quantity">
                    <!-- <span @click="decrement(row.item.id)">-</span> -->
                    <input
                      :id="'qtybox_' + row.item.id"
                      v-model="row.item.quantity"
                      v-on:input="addQtyToUpdate(row.item.id)"
                      type="number"
                      min="0"
                    />
                    <!-- <span @click="increment(row.item.id)" class="lastspan">+</span> -->
                  </div>
                  <input
                    :id="'cart_avl_qtybox_' + row.item.id"
                    v-model="row.item.available_qty"
                    type="hidden"
                  />
                  <!-- <a
                    href="javascript:;"
                    @click="updateQtyBybutton(row.item.id)"
                    class="updateqn"
                  >
                    <input
                      :id="'cart_avl_qtybox_' + row.item.id"
                      v-model="row.item.available_qty"
                      type="hidden"
                    />
                    <b-img src="/images/update.svg"></b-img>Update
                  </a> -->
                </template>
                <template v-slot:cell(uom)="row">
                  <v-select
                    :options="product_uom"
                    v-model="row.item.uom"
                    @input="addUOMToUpdate(row.item.id)"
                    :searchable="false"
                    :reduce="(product_uom) => product_uom.value"
                    label="text"
                    :id="'uom_' + row.item.id"
                    :clearable="false"
                    placeholder="Please select"
                  ></v-select>
                </template>

                <template v-slot:cell(addtoquote)="row">
                  <a href="javascript:;" @click="deleteQuote(row.item.id)">
                    <b-img
                      class="removesvg"
                      src="/images/delete.svg"
                      alt="delete"
                    ></b-img>
                  </a>
                </template>
              </b-table>
            </div>

            <div class="continueshop">
              <div class="contbtn">
                <a href="/store/category/solid" class="backinfo"
                  ><b-img src="/images/arrow.svg" alt="arrow"></b-img>Continue
                  Shopping</a
                >
              </div>
              <div
                class="text-block-4 h6 quotebtn add button"
                v-if="this.miniCartList.length > 0"
              >
                <a
                  href="javascript:;"
                  @click="submitQuote()"
                  class="addquote viewquote"
                  >Send Quote Request</a
                >
              </div>
            </div>

            <div v-if="this.miniCartList.length <= 0">
              <p class="emptyquote">Your Quote List is empty.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import ResourceMixin from "../mixins/resources";

export default {
  components: {
    CommonHead,
    CommonFoot,
    "v-select": vSelect,
  },
  beforeMount() {
    this.initForm();
    this.getCountry();
  },
  mounted() {
    this.loadCartList();
  },
  data() {
    return {
      notesToUpdate: {},
      qtyToUpdate: {},
      uomToUpdate: {},
      loadProducts: [],
      checkboxes: [],
      products: [],
      quantity: [],
      isCheckAll: false,
      form: {
        products: [],
        quantity: [],
        user_id: null,
      },
      formnote: {
        val: null,
        id: null,
      },
      product_uom: [
        { value: "EA", text: "EA" },
        { value: "LB", text: "LB" },
      ],
      show: true,

      fields: [
        {
          key: "name",
          label: "Name",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "type",
          label: "Type",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },

        {
          key: "head_style",
          label: "Head Style",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "material",
          label: "Material",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        // {
        //     key: "finish",
        //     label: "Finish",
        //     thClass: "tbheader",
        //     tdClass: "tbColumn"
        // },
        {
          key: "dia",
          label: "Diameter",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "length",
          label: "Length",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "additional_notes",
          label: "Notes",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "quantity",
          label: "Quantity",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "uom",
          label: "UOM",
          thClass: "tbheader",
          tdClass: "tbColumn",
        },
        {
          key: "addtoquote",
          label: "Products",
          thClass: "tbheaderAction",
          tdClass: "tbColumn",
        },
      ],
    };
  },
  mixins: [ResourceMixin],
  computed: {
    ...mapGetters(["user", "cartlist"]),
    miniCartList() {
      return this.cartlist.success;
    },
  },

  methods: {
    addNotesToUpdate(id) {
      console.log(this.miniCartList, id);
      let item = this.miniCartList.filter((d) => d.id === id);
      if (item.length) {
        this.notesToUpdate[id] = item[0].additional_notes;
      }
    },
    addQtyToUpdate(id) {
      console.log(this.miniCartList, id);
      let item = this.miniCartList.filter((d) => d.id === id);
      if (item.length) {
        this.qtyToUpdate[id] = item[0].quantity;
      }
    },
    addUOMToUpdate(id) {
      console.log(this.miniCartList, id);
      let item = this.miniCartList.filter((d) => d.id === id);
      if (item.length) {
        this.uomToUpdate[id] = item[0].uom;
      }
    },
    checkAll() {
      this.checkboxes = [];
      let mythis = this;

      if (this.isCheckAll == "1") {
        // Check all

        this.miniCartList.forEach(function (lp) {
          console.log(lp.id);
          mythis.checkboxes.push(lp.id);
        });
      }
    },
    updateCheckall: function () {
      if (this.checkboxes.length == this.miniCartList.length) {
        this.isCheckAll = "1";
      } else {
        this.isCheckAll = false;
      }
    },

    initForm() {
      this.form = new Form({
        products: [],
        quantity: [],
        user_id: this.user.id,
      });
      //this.getState(231);
    },
    increment(id) {
      var val = parseInt(document.getElementById("qtybox_" + id).value);
      val = val + 1;
      document.getElementById("qtybox_" + id).value = val;
      this.updateQty(val, id);
    },
    decrement(id) {
      var val = parseInt(document.getElementById("qtybox_" + id).value);
      if (val === 1) {
        console.log("Negative quantity not allowed");
      } else {
        val = val - 1;
        document.getElementById("qtybox_" + id).value = val;
        this.updateQty(val, id);
      }
    },
    updateQty(val, id) {
      this.$store
        .dispatch("updateCartQty", {
          val: val,
          id: id,
        })
        .finally(() => {
          this.isLoading = true;
          this.loadCartList();
        });
    },
    updateQtyBybutton(id) {
      var val = $("#qtybox_" + id).val();
      var avl_val = $("#cart_avl_qtybox_" + id).val();
      if (val > 0) {
        if (parseFloat(val) <= parseFloat(avl_val)) {
          this.$store
            .dispatch("updateCartQty", {
              val: val,
              id: id,
            })
            .finally(() => {
              this.isLoading = true;

              this.loadCartList();
            });
        } else {
          this.$notify({
            text: "Quantity should be less than Available Quantity!",
            type: "error",
          });
        }
      } else {
        this.$notify({
          text: "Quantity should be greater than 0 (Zero)!",
          type: "error",
        });
      }
    },
    updateUOM(id) {
      let mythis = this;
      setTimeout(function () {
        var val = $("#uom_" + id + " .vs__selected").text();
        val = val.trim();
        console.log(id + val);
        mythis.$store
          .dispatch("updateUOM", {
            val: val,
            id: id,
          })
          .finally(() => {
            mythis.isLoading = true;

            mythis.loadCartList();
          });
      }, 800);
    },
    updateAdditionalNote(id) {
      var val = $("#additional_notes_" + id).val();

      this.formnote = new Form({
        val: val,
        id: id,
      });
      this.$store
        .dispatch("updateCartNote", {
          form: this.formnote,
        })
        .finally(() => {
          this.isLoading = true;

          this.loadCartList();
        });
    },
    updateSingleNote(id, val) {
      return new Promise((resolve) => {
        this.formnote = new Form({
          val: val,
          id: id,
        });
        this.$store
          .dispatch("updateCartNote", {
            form: this.formnote,
          })
          .finally(() => {
            resolve(true);
          });
      });
    },
    updateSingleQty(id, val) {
      return new Promise((resolve) => {
        this.$store
          .dispatch("updateCartQty", {
            val: val,
            id: id,
          })
          .finally(() => {
            resolve(true);
          });
      });
    },
    updateSingleUOM(id, val) {
      return new Promise((resolve) => {
        this.$store
          .dispatch("updateUOM", {
            val: val,
            id: id,
          })
          .finally(() => {
            resolve(true);
          });
      });
    },

    deleteQuote(prod_id) {
      if (confirm("Are you sure to delete this quote!")) {
        //if (this.checkboxes.length > 0) {
        this.$store
          .dispatch("quoteDelete", {
            product_id: prod_id,
          })
          .then(() => {
            this.loadCartList();
          })
          .catch((error) => {
            if (this.form.hasErrors()) {
              this.$notify({
                text: "There is some validation error. Please correct and try again!",
                type: "error",
              });
            } else {
              this.$notify({
                text: "Something went wrong!",
                type: "error",
              });
            }
          });
      }
    },
    submitQuote() {
      let _qty = Object.values(this.qtyToUpdate)
        .map((d) => parseFloat(d))
        .filter((d) => d <= 0);
      let _decimal = Object.values(this.qtyToUpdate)
        .map((d) => parseFloat(d))
        .map((d) => d % 1)
        .filter((d) => d !== 0);
      console.log(_qty);
      if (_qty.length) {
        this.$notify({
          text: "Please enter valid quantity",
          type: "error",
        });
        return;
      }
      if (_decimal.length) {
        this.$notify({
          text: "Decimal quantity is not allowed",
          type: "error",
        });
        return;
      }
      console.log(this.qtyToUpdate, this.notesToUpdate);
      Promise.all(
        Object.entries(this.notesToUpdate).map(([id, val]) => {
          return this.updateSingleNote(id, val);
        })
      )
        .then(() => {
          Promise.all(
            Object.entries(this.qtyToUpdate).map(([id, val]) => {
              return this.updateSingleQty(id, val);
            })
          )
            .then(() => {
              Promise.all(
                Object.entries(this.uomToUpdate).map(([id, val]) => {
                  return this.updateSingleUOM(id, val);
                })
              )
                .then(() => {
                  console.log("done");
                  // this.isLoading = true;
                  this.$store
                    .dispatch("sendQuoteRequest", {
                      user_id: this.user.id,
                    })
                    .then((response) => {
                      this.isLoading = true;
                      if (
                        response.data.success.id != 0 &&
                        response.data.success.id != ""
                      ) {
                        this.$router.push("/store/quote/thankyou");
                      } else {
                        this.$router.push("/store/login");
                      }
                    })
                    .catch((error) => {
                      if (this.form.hasErrors()) {
                        this.$notify({
                          text: "There is some validation error. Please correct and try again!",
                          type: "error",
                        });
                      } else {
                        this.$notify({
                          text: "Something went wrong!",
                          type: "error",
                        });
                      }
                    });
                })
                .catch((e) => {
                  console.log(e);
                  this.$notify({
                    text: "Something went wrong!",
                    type: "error",
                  });
                });
            })
            .catch((e) => {
              console.log(e);
              this.$notify({
                text: "Something went wrong!",
                type: "error",
              });
            });
        })
        .catch((e) => {
          console.log(e);
          this.$notify({
            text: "Something went wrong!",
            type: "error",
          });
        });
    },
    rowClass(item, type) {
      return "tbRows";
    },
    loadCartList() {
      this.$store.dispatch("loadCartList").then((loadProducts) => {});
    },
    getCountry() {
      this.$store.dispatch("loadCountries").then((countries) => {
        this.countrylist = countries.data;
      });
    },
    getState(value) {
      this.$store.dispatch("getState", this.form.country).then((response) => {
        this.statelist = response.data;
      });
      this.form.state = parseInt(this.statelist[0].text);
    },
    gotosinglePage(productid) {
      this.$router.push({ path: "/store/product/" + productid });
    },
  },
};
</script>
<style>
.productlisttable .table .tbColumn img.tickimg {
  display: none;
}
.qtyfield {
  width: 80px;
}
.emptyquote {
  padding: 15px;
  color: #f2612c;
}
.loginform.w-form {
  background: #fff;
  padding: 30px;
}
.page--quotelist .haserror input.vs__search {
  border-color: #fff;
}
.vs__dropdown-toggle {
  border-radius: 0;
  min-height: 38px;
}
.page--quotelist .section.cc-store-home-wrap {
  overflow: inherit;
}
</style>
