<template>
  <div>
    <CommonHead></CommonHead>
    <div class="section cc-store-home-wrap">
      <div class="container">
        <div class="contact-container">
          <div
            data-delay="4000"
            data-animation="slide"
            data-autoplay="1"
            data-duration="500"
            data-infinite="1"
            class="slider-2 contslider w-slider"
          >
            <div class="w-slider-mask">
              <div class="slide w-slide">
                <div class="div-block-20 contactblock">
                  <div>
                    <img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt=""
                      class="image"
                    />
                  </div>
                  <h5>Benny</h5>
                  <p>
                    Needed to know how many each were in a pound of rivets. Mr.
                    Al Felice, responded the next day, great customer service.
                  </p>
                </div>
              </div>
              <div class="slide-2 w-slide">
                <div class="div-block-20">
                  <div>
                    <img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt=""
                      class="image"
                    />
                  </div>
                  <h5>Katy</h5>
                  <p>
                    I appreciate the quick responses when I send over an e-mail.
                    Nothing is more frustrating than vendors who take a week to
                    get back to you. Any time I have contacted anyone at Rapid
                    Rivet, they have done a nice job responding to my request
                    and any questions I might have. Thank you!
                  </p>
                </div>
              </div>
              <div class="slide-3 w-slide">
                <div class="div-block-20">
                  <div>
                    <img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt=""
                      class="image"
                    />
                  </div>
                  <h5>Stephanie</h5>
                  <p>
                    Kevin is ALWAYS on point! He is courteous and really knows
                    his products! I’ve never had a problem with product or
                    shipping, the Rapid Rivet team is wonderful!
                  </p>
                </div>
              </div>
              <div class="slide-4 w-slide">
                <div class="div-block-20">
                  <div>
                    <img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Star.svg"
                      width="21"
                      height="21"
                      alt=""
                    /><img
                      src="/images/Dark-traignle.svg"
                      width="35"
                      height="35"
                      alt=""
                      class="image"
                    />
                  </div>
                  <h5>Diane</h5>
                  <p>
                    I have been dealing with Rapid Rivet for many years. They
                    have always been prompt, efficient and courteous.
                  </p>
                </div>
              </div>
            </div>
            <div class="left-arrow-3 w-slider-arrow-left">
              <div class="w-icon-slider-left"></div>
            </div>
            <div class="right-arrow-3 w-slider-arrow-right">
              <div class="w-icon-slider-right"></div>
            </div>
            <div class="w-slider-nav w-round"></div>
          </div>
          <div class="div-block-54-copy contactskew">
            <img
              src="/images/matt-artz-64-xGOdUEnU-unsplash-7ceede60-p-1080_1.jpeg"
              alt=""
              class="image-17"
            />
          </div>
          <div class="div-block-20 singletest">
            <div>
              <img src="/images/Star.svg" width="21" height="21" alt="" /><img
                src="/images/Star.svg"
                width="21"
                height="21"
                alt=""
              /><img src="/images/Star.svg" width="21" height="21" alt="" /><img
                src="/images/Star.svg"
                width="21"
                height="21"
                alt=""
              /><img src="/images/Star.svg" width="21" height="21" alt="" />
            </div>
            <h5>Katy</h5>
            <p>
              I appreciate the quick responses when I send over an e-mail.
              Nothing is more frustrating than vendors who take a week to get
              back to you. Any time I have contacted anyone at Rapid Rivet, they
              have done a nice job responding to my request and any questions I
              might have. Thank you!
            </p>
          </div>
          <div class="div-block-10">
            <div class="div-block-12">
              <h4 class="heading">Contact</h4>
              <img
                src="/images/Asset-1.svg"
                width="35"
                height="35"
                alt=""
                class="image"
              />
            </div>
            <div class="home-section-wrap">
              <h5 class="heading-12">Call Toll Free : 800 - ( 727 - 4378 )</h5>
              <p class="paragraph-7">
                Need a quick quote, fill out the below form and one of our
                experienced sales associated will contact you shortly.
              </p>
              <div class="w-form">
                <form
                  id="wf-form-Contact-Us"
                  name="wf-form-Contact-Us"
                  data-name="Contact Us"
                >
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="Firstname-3" class="field-label-16"
                        >First name *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="Firstname"
                        data-name="Firstname"
                        id="Firstname-3"
                        required=""
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50">
                      <label for="Lastname-3" class="field-label-17"
                        >Last name *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="Lastname"
                        data-name="Lastname"
                        id="Lastname-3"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="Company-Name-2" class="field-label-18"
                        >Company name *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="Company-Name"
                        data-name="Company Name"
                        id="Company-Name-2"
                        required=""
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50 typein">
                      <label for="Type-of-Company" class="field-label-19"
                        >Type of industry *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="Type-of-Company"
                        data-name="Type of Company"
                        id="Type-of-Company"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="Phone" class="field-label-20">Phone *</label
                      ><input
                        type="number"
                        class="w-input"
                        maxlength="10"
                        name="Phone"
                        data-name="Phone"
                        pattern="^[0-9-+s()]*$"
                        minlength="10"
                        id="phoneField"
                        required=""
                      />
                    </div>
                    <div class="_35"></div>
                    <div class="div-block-50">
                      <label for="Email-2" class="field-label-21">EMail *</label
                      ><input
                        type="email"
                        class="w-input"
                        maxlength="256"
                        name="Email"
                        data-name="Email"
                        id="Email-2"
                        required=""
                      />
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49">
                      <label for="How-did-you-find-us" class="field-label-22"
                        >How did you find us?</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="How-did-you-find-us"
                        data-name="How did you find us"
                        id="How-did-you-find-us"
                        required=""
                      />
                    </div>
                    <div class="div-block-50 uploadsheet">
                      <label for="name">Upload Spec Sheet</label>
                      <div class="div-block-51">
                        <div class="div-block-52">
                          <input
                            type="submit"
                            value="Choose File"
                            data-wait="Please wait..."
                            class="button secondary w-button"
                          />
                          <div class="_14"></div>
                          <p>No File Chosen</p>
                        </div>
                        <p class="caption">
                          Browse to the document you intend to upload
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-48">
                    <div class="div-block-49 partn">
                      <label for="name">Part Number *</label
                      ><input
                        type="text"
                        class="w-input"
                        maxlength="256"
                        name="name-3"
                        data-name="Name 3"
                        id="name-3"
                      />
                    </div>
                    <div class="div-block-50">
                      <label for="message-2" class="field-label-23"
                        >Message *</label
                      ><textarea
                        required=""
                        maxlength="5000"
                        id="message-2"
                        name="message"
                        data-name="message"
                        class="textarea-2 w-input"
                      ></textarea>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="hidddenfield">
                    <div class="div-block-48">
                      <div class="div-block-49">
                        <label for="name-4">Part Number *</label
                        ><input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          name="name-3"
                          data-name="Name 3"
                          id="name-3"
                        />
                      </div>
                      <div class="_35"></div>
                      <div class="div-block-50">
                        <label for="name-4">Notes *</label
                        ><input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          name="name-3"
                          data-name="Name 3"
                          id="name-3"
                        />
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div class="div-block-49">
                        <label for="name">Target Price EA *</label
                        ><input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          name="name-3"
                          data-name="Name 3"
                          id="name-3"
                        />
                      </div>
                      <div class="_35"></div>
                      <div class="div-block-50">
                        <label for="name">Manufacturer *</label
                        ><input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          name="name-3"
                          data-name="Name 3"
                          id="name-3"
                        />
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div class="div-block-49">
                        <label for="name">Quantity *</label
                        ><input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          name="name-3"
                          data-name="Name 3"
                          id="name-3"
                        />
                      </div>
                      <div class="_35"></div>
                      <div class="div-block-50">
                        <label for="name">Material *</label
                        ><input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          name="name-3"
                          data-name="Name 3"
                          id="name-3"
                        />
                      </div>
                    </div>
                    <div class="_14"></div>
                    <div class="div-block-48">
                      <div class="div-block-49">
                        <label for="name">Quantity *</label
                        ><input
                          type="text"
                          class="w-input"
                          maxlength="256"
                          name="name-3"
                          data-name="Name 3"
                          id="name-3"
                        />
                      </div>
                      <div class="_35"></div>
                    </div>
                  </div>
                  <div class="_14"></div>
                  <div class="div-block-50">
                    <div
                      data-sitekey="6LdoywEVAAAAAI3YaQRr8jQvIUcjS854I-Vin_Iu"
                      class="w-form-formrecaptcha g-recaptcha g-recaptcha-error g-recaptcha-disabled"
                    ></div>
                  </div>
                  <div class="_14"></div>
                  <input
                    type="submit"
                    value="Submit"
                    data-wait="Please wait..."
                    class="button w-button"
                  />
                </form>
                <div class="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CommonFoot></CommonFoot>
  </div>
</template>

<script>
import CommonHead from "@/components/CommonHead";
import CommonFoot from "@/components/CommonFoot";
export default {
  components: {
    CommonHead,
    CommonFoot,
  },
};
</script>
<style>
</style>
