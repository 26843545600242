import Store from "../../store";
import axios from "axios";

export default function(to, from, next) {
  // This guard is fired prior to every native HTTP request to load the application.
  // It will set the user, as well as other various application properties if the
  // user is authenticated.
  if (Store.getters.user === null) {
    axios
      .get("/api/users/me")
      .then((response) => {
        if (response.data.user.id === 0) {
          Store.dispatch("setUserData", response.data);
          Store.dispatch("setPriceLvl", "none");
        } else {
          Store.dispatch("setUserData", response.data);
          if(response.data.user.priceDetails){
            Store.dispatch(
              "setPriceLvl",
              response.data.user.priceDetails.PriceLvlCode
            );
          }
        }
        next();
      })
      .catch((error) => {
        Store.dispatch("setUserData", {});
        Store.dispatch("setPriceLvl", "none");
        next();

        if (
          !error.response ||
          error.response.status !== 418
        ) {
          throw new Error(error);
        }
      });
  } else {
    next();
  }
}
